<template>
  <v-container grid-list-md pa-0 relative class="page-layout">
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card>
          <!--{{$auth.permissions()}}-->
          <v-card>
            <v-card-title class="title">
              <!-- <v-icon>line_style</v-icon>  -->
              &nbsp; Inventory Mapping Structure
              <v-spacer></v-spacer>
              <transition
                name="animate-css-transition"
                enter-active-class="animated fadeInRight"
                leave-active-class="animated fadeOutRight"
              >
                <add-button
                  permission="account-mapping-create"
                  @action="addMapping"
                  >New mapping
                </add-button>
              </transition>
            </v-card-title>
          </v-card>
          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :search="search"
            :options.sync="pagination"
            :loading="form.loading"
            :footer-props="footerProps"
          >
            <!-- <template slot="items" slot-scope="props"> -->
            <template v-slot:item="{ index, item }">
              <tr>
                <td class="text-xs-left">
                  {{ index + 1 }}
                </td>
                <td style="font-weight: bold" class="text-xs-left">
                  {{ item.mapping_param.name }}
                </td>
                <td class="text-xs-left">
                  {{ item.purchase_ledger.name }}
                </td>
                <td class="text-xs-left">{{ item.sale_ledger.name }}</td>
                <td class="text-xs-center" v-if="item.is_verify == 1">
                  <!-- <v-btn small icon color="primary" class="pa-0 ma-1 white--text" slot="activator"> -->
                  <v-icon small>check_circle</v-icon>
                  <!-- </v-btn> -->
                </td>
                <td class="text-xs-center" v-else>
                  <v-btn
                    small
                    icon
                    color="green"
                    class="pa-0 ma-1 white--text"
                    slot="activator"
                    @click="verify(item.id)"
                  >
                    <v-icon small>check_circle</v-icon>
                  </v-btn>
                  <edit-button
                    permission="account-mapping-update"
                    @agree="form.edit(item)"
                  />
                  <delete-button
                    permission="account-mapping-delete"
                    @agree="form.delete(item.id)"
                  />
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
          <v-card-actions>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-dialog
        v-model="form.dialog"
        persistent
        max-width="800px"
        style="margin-top: -120px"
      >
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Add/Update</span>
          </v-card-title>
          <br />
          <v-card-text class="pb-1">
            <v-form
              ref="form"
              @submit.prevent="save"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="save"
              lazy-validation
            >
              <v-layout row wrap>
                <!-- :loading="fetchingGrade" -->
                <v-flex xs4 sm4>
                  <v-select
                    outlined
                    dense
                    :items="mapping_params"
                    class="pa-0"
                    label="Mapping Param"
                    v-model="form.mapping_param_id"
                    @change="getMappingHead(form.mapping_param_id)"
                  />
                </v-flex>
                <!-- :loading="fetchingGrade" -->
                <v-flex xs4 sm4>
                  <v-select
                    outlined
                    dense
                    :items="purchase_ledger_heads"
                    class="pa-0"
                    label="Purchase Ledger Head"
                    v-model="form.purchase_ledger_id"
                  />
                </v-flex>
                <!-- :loading="fetchingGrade" -->
                <v-flex xs4 sm4>
                  <v-select
                    outlined
                    dense
                    :items="sales_ledger_heads"
                    class="pa-0"
                    label="Sales Ledger Head"
                    v-model="form.sale_ledger_id"
                  />
                </v-flex>
                <v-flex xs10>
                  <div style="color: red; font-weight: bold">
                    <strong>
                      Please be cautious when mapping. This will directly impact
                      on Account Sync.
                    </strong>
                  </div>
                </v-flex>

                <v-flex xs6 sm6> </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              outlined
              @click="(form.dialog = false), form.reset()"
              >Close</v-btn
            >
            <v-btn color="success" outlined @click="save">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="verifyDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="title pa-3 warning white--text">
            <v-icon class="mr-2 white--text">warning</v-icon>
            Please, wait!
          </v-card-title>
          <v-card-text class="ma-0"
            >Are you sure you want to Verify this mapping?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" outlined small @click="verifyDialog = false"
              >No</v-btn
            >
            <v-btn color="warning" outlined small @click="verifySubmit"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from 'vuex';
import Form from '@/library/Form';
import { getPropertyFromArrayObject } from '@/library/helpers';

export default {
  data: () => ({
    form: new Form(
      {
        mapping_param_id: '',
        sale_ledger_id: '',
        purchase_ledger_id: '',
      },
      '/api/account/inventory/mapping'
    ),
    search: null,
    fetchingGrade: false,
    rowsPerPageItems: [10, 25, 50, 75, 100],
    pagination: {
      rowsPerPage: 25,
    },
    headers: [
      { text: '#', align: 'left', value: 'id', width: 20, sortable: false },
      { text: 'Mapping Param', align: 'left', value: 'title', sortable: false },
      {
        text: 'Purchase Ledger',
        align: 'left',
        value: 'purchase_ledger_id',
        sortable: false,
      },
      {
        text: 'Sales Ledger',
        align: 'left',
        value: 'sale_ledger_id',
        sortable: false,
        width: 250,
      },
      {
        text: 'Action',
        align: 'center',
        value: 'action',
        sortable: false,
        width: 250,
      },
    ],
    mapping_params: [],
    purchase_ledger_heads: [],
    sales_ledger_heads: [],
    verifyDialog: false,
    id: '',
    footerProps: {
      'items-per-page-options': [10, 25, 50, 100, -1],
    },
  }),

  computed: {
    ...mapState(['batch']),
  },

  mounted() {},

  watch: {
    pagination: function () {
      this.get();
    },
    batch: function (value) {
      this.get();
    },
  },

  methods: {
    queryString() {
      let json = this.pagination;
      return (
        '?' +
        Object.keys(json)
          .map(function (key) {
            if (![null, undefined].includes(json[key]))
              return (
                encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
              );
          })
          .join('&')
      );
    },

    get(params) {
      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
        this.mapping_params = data.mapping_params.map((mp) => {
          return { value: mp.id, text: mp.name };
        });
      });
    },

    save() {
      this.form.store();
    },
    verify(id) {
      this.verifyDialog = true;
      this.id = id;
    },
    verifySubmit() {
      this.$rest
        .get('/api/account/inventory/mapping/verify?mapId=' + this.id, {
          // mapId: this.id
        })
        .then(({ data }) => {
          this.$events.fire('notification', {
            message: data.message,
            status: 'success',
          });
          this.verifyDialog = false;
          this.get();
        })
        .catch((e) => {
          this.verifyDialog = false;
          this.$events.fire('notification', {
            message: data.message,
            status: 'error',
          });
        });
    },
    addMapping() {
      this.form.dialog = true;

      // let mappingParams = getPropertyFromArrayObject(
      //   "mapping_param_id",
      //   this.form.items.data
      // );
      // let saleLedgerHeads = getPropertyFromArrayObject(
      //   "sale_ledger_id",
      //   this.form.items.data
      // );
      // let purchseLedgerHeads = getPropertyFromArrayObject(
      //   "purchase_ledger_id",
      //   this.form.items.data
      // );

      // this.mapping_params = this.mapping_params.diff(mappingParams, "value");
      // this.sales_ledger_heads = this.sales_ledger_heads.diff(
      //   saleLedgerHeads,
      //   "value"
      // );
      // this.purchase_ledger_heads = this.purchase_ledger_heads.diff(
      //   purchseLedgerHeads,
      //   "value"
      // );
    },
    getMappingHead(mappingValue) {
      this.$rest
        .get('/api/account/inventory/mapping?mapId=' + mappingValue, {})
        .then(({ data }) => {
          this.purchase_ledger_heads = data.purchase_ledger.map((mp) => {
            return { value: mp.id, text: mp.name };
          });
          this.sales_ledger_heads = data.sale_ledger.map((mp) => {
            return { value: mp.id, text: mp.name };
          });
        })
        .catch((e) => {
          this.$events.fire('notification', {
            message: data.message,
            status: 'error',
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
